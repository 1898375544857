// import '../../config';
import { config } from '@abyss/web/tools/config';
import { http } from '../http';
import { lagoon } from '@optum-lagoon/sdk';

let FEATURES = {};

/*
export const loadFeatures = async (appKey, callback) => {
  let dataURL = config('FEATURES_DATA_URL');

  if (appKey) {
    dataURL = dataURL?.replace('hhs', appKey);
  }

  await http({
    method: 'GET',
    url: dataURL,
  })
    .then(response => {
      const featureData = response.data || {};
      Object.assign(FEATURES, featureData);
      console.log('FEATURES', FEATURES);
      if (callback) {
        callback(FEATURES);
      }
    })
    .catch(() => {
      if (callback) {
        callback({});
      }
    });
};

export const getFeature = id => {
  // RDR Modified for acknowledge tins
  // For local Running added, comment to deploy

  // if (id === 'HRSA_UIP_DISABLE_ADD_TIN') return false;
  // if (id === 'HRSA_UIP_ENABLE_ADD_TIN') return true;
  // if (id === 'HRSA_UIP_DISABLE_ADD_TIN_MSG') return 'Disable add tin message';
  // if (id === 'DISABLE_SUBMIT_INFO') return false;
  // if (id === 'DISABLE_SUBMIT_NEW_INFO') return true;
  // if (id === 'HRSA_UIP_ENABLE_ADD_TIN_MSG')
  //   return 'Thank you for the crucial role you’ve played in testing, treating and vaccinating uninsured individuals in your communities as part of the COVID-19 response. The Health Resources and Services Administration’s (HRSA) COVID-19 Uninsured Program has stopped accepting claims for testing, treatment, and vaccine administration due to a lack of sufficient funds. Any claims submitted at this time will be rejected. </p><b>Please Note: This program portal is only available for a limited time for providers directly contacted by HRSA to take additional actions to comply with program requirements.</b> </p><b>More Information</b>: If you have any questions, please refer to <a href="https://www.hrsa.gov/coviduninsuredclaim/submission-deadline" target="_blank">https://www.hrsa.gov/coviduninsuredclaim/submission-deadline</a> or contact the Provider Support Line at (866) 569-3522; for TTY dial 711. Hours of operation are 8:00 AM to 10:00 PM CT, Monday through Friday.';
  // if (id === 'HRSA_UIP_ERROR_ADD_TIN_MSG')
  //   return 'TIN not accepted. This program portal is only available for a limited time for providers directly contacted by HRSA to take additional actions to comply with program requirements. If you have any questions, please refer to <a href="https://www.hrsa.gov/coviduninsuredclaim/submission-deadline" title="https://www.hrsa.gov/coviduninsuredclaim/submission-deadline" target="_blank">https://www.hrsa.gov/coviduninsuredclaim/submission-deadline </a> or contact the Provider Support Line at (866) 569-3522; for TTY dial 711. Hours of operation are 8:00 AM to 10:00 PM CT, Monday through Friday.';
  // if (id === 'SKIP_IRS_VALIDATION_ENABLED') return false;
  // if (id === 'HRSA_UIP_PROV_ROSTER_ENABLED') return true;
  // if (id === ' HRSA_UIP_PATIENT_ROSTER_ENABLED') return true;
  // if (id === 'HRSA_FOOTER_COPYRIGHT_TXT')
  //  return '2024 UnitedHealth Group, Inc. All rights reserved.'; 

  // // End of For local Running added
  return FEATURES[id] || false;
};

*/

///////////////////////////////////////////////////////////////////////// EARLIER ABOVE ////////////////////////////////////////

export const loadFeatures = async (appKey, callback) => {
  // console.log('appKey', appKey);                                   // appKey = 'registration-toggles';
  let lagoonenv = config('FEATURES_ENV');
  await lagoon.initialize('hhs', lagoonenv, {loadTables:true, loadForms:false})
    .then(response => {
      // console.log('Inside Load Features:');
      appKey.forEach(key => {
        const lagoonTable = lagoon.getTable(key).getRow(null);
        // console.log('FEATURES from lagoon for key ', key, lagoonTable); 
        if (lagoonTable) {
          lagoonTable.forEach((elem, index) => {
            const key = elem['key'];
            const value = [elem['value']];
            FEATURES[key] = value;
          });       
        } 
      });
      console.log('FEATURES_ENV', lagoonenv);
      // console.log('FEATURES loaded from lagoon ', FEATURES);
      if (callback) {
        callback(FEATURES);
      }
    });
};

export const getFeature = id => {
  const returnVal = FEATURES[id] || 'false'; 
  if (returnVal=='true') return true;
  if (returnVal=='false') return false;
  return returnVal;
};  
